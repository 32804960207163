import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3f8267b4 = () => interopDefault(import('..\\pages\\BlankPage.vue' /* webpackChunkName: "pages/BlankPage" */))
const _90e9b0fa = () => interopDefault(import('..\\pages\\Index.vue' /* webpackChunkName: "pages/Index" */))
const _0386d6ff = () => interopDefault(import('..\\pages\\about\\About.vue' /* webpackChunkName: "pages/about/About" */))
const _8d82a85e = () => interopDefault(import('..\\pages\\about\\Contacts.vue' /* webpackChunkName: "pages/about/Contacts" */))
const _fc440fc0 = () => interopDefault(import('..\\pages\\about\\ShipmentPay.vue' /* webpackChunkName: "pages/about/ShipmentPay" */))
const _077e774a = () => interopDefault(import('..\\pages\\search\\Index.vue' /* webpackChunkName: "pages/search/Index" */))
const _35f1b825 = () => interopDefault(import('..\\pages\\products\\extraction\\Dna2.vue' /* webpackChunkName: "pages/products/extraction/Dna2" */))
const _7d14d575 = () => interopDefault(import('..\\pages\\products\\extraction\\DnaFfpeIvd.vue' /* webpackChunkName: "pages/products/extraction/DnaFfpeIvd" */))
const _67476798 = () => interopDefault(import('..\\pages\\products\\extraction\\Index.vue' /* webpackChunkName: "pages/products/extraction/Index" */))
const _1342bc07 = () => interopDefault(import('..\\pages\\products\\genotyping\\Brca.vue' /* webpackChunkName: "pages/products/genotyping/Brca" */))
const _37c7ef4e = () => interopDefault(import('..\\pages\\products\\genotyping\\Ces1.vue' /* webpackChunkName: "pages/products/genotyping/Ces1" */))
const _d4f1686c = () => interopDefault(import('..\\pages\\products\\genotyping\\Cyp2c19.vue' /* webpackChunkName: "pages/products/genotyping/Cyp2c19" */))
const _5509d566 = () => interopDefault(import('..\\pages\\products\\genotyping\\Cyp2c9vkorc1.vue' /* webpackChunkName: "pages/products/genotyping/Cyp2c9vkorc1" */))
const _0b0fd047 = () => interopDefault(import('..\\pages\\products\\genotyping\\Egfr.vue' /* webpackChunkName: "pages/products/genotyping/Egfr" */))
const _7beb1ef4 = () => interopDefault(import('..\\pages\\products\\genotyping\\F2f5.vue' /* webpackChunkName: "pages/products/genotyping/F2f5" */))
const _031acec9 = () => interopDefault(import('..\\pages\\products\\genotyping\\Index.vue' /* webpackChunkName: "pages/products/genotyping/Index" */))
const _32af082d = () => interopDefault(import('..\\pages\\products\\genotyping\\Insider.vue' /* webpackChunkName: "pages/products/genotyping/Insider" */))
const _ee1a0dec = () => interopDefault(import('..\\pages\\products\\genotyping\\Nat2.vue' /* webpackChunkName: "pages/products/genotyping/Nat2" */))
const _609a1e4a = () => interopDefault(import('..\\pages\\products\\genotyping\\Ras.vue' /* webpackChunkName: "pages/products/genotyping/Ras" */))
const _990a21dc = () => interopDefault(import('..\\pages\\products\\genotyping\\Slco1b1.vue' /* webpackChunkName: "pages/products/genotyping/Slco1b1" */))
const _8e071a24 = () => interopDefault(import('..\\pages\\search\\components\\AppSearchShowMore.vue' /* webpackChunkName: "pages/search/components/AppSearchShowMore" */))
const _13a607b8 = () => interopDefault(import('..\\pages\\search\\components\\SearchBlock.vue' /* webpackChunkName: "pages/search/components/SearchBlock" */))
const _161330fc = () => interopDefault(import('..\\pages\\search\\components\\SearchResult.vue' /* webpackChunkName: "pages/search/components/SearchResult" */))
const _64b6fd9a = () => interopDefault(import('@/layouts/MainPage' /* webpackChunkName: "" */))
const _59a7c9bc = () => interopDefault(import('@/pages/Index' /* webpackChunkName: "" */))
const _1f40664a = () => interopDefault(import('@/pages/about/About' /* webpackChunkName: "" */))
const _db2a7776 = () => interopDefault(import('@/pages/products/extraction/Index' /* webpackChunkName: "" */))
const _07166fb0 = () => interopDefault(import('@/pages/products/extraction/Dna2' /* webpackChunkName: "" */))
const _63c76590 = () => interopDefault(import('@/pages/products/extraction/DnaFfpeIvd' /* webpackChunkName: "" */))
const _460de274 = () => interopDefault(import('@/pages/products/genotyping/Index' /* webpackChunkName: "" */))
const _0a17edd0 = () => interopDefault(import('@/pages/products/genotyping/Insider' /* webpackChunkName: "" */))
const _994b1b74 = () => interopDefault(import('@/pages/products/genotyping/Ras' /* webpackChunkName: "" */))
const _9023f9e8 = () => interopDefault(import('@/pages/products/genotyping/Egfr' /* webpackChunkName: "" */))
const _90266268 = () => interopDefault(import('@/pages/products/genotyping/Brca' /* webpackChunkName: "" */))
const _901bf562 = () => interopDefault(import('@/pages/products/genotyping/Nat2' /* webpackChunkName: "" */))
const _4faf8e12 = () => interopDefault(import('@/pages/products/genotyping/Cyp2c9vkorc1' /* webpackChunkName: "" */))
const _5fdeb996 = () => interopDefault(import('@/pages/products/genotyping/Cyp2c19' /* webpackChunkName: "" */))
const _9025d7c4 = () => interopDefault(import('@/pages/products/genotyping/Ces1' /* webpackChunkName: "" */))
const _07a92a7d = () => interopDefault(import('@/pages/products/genotyping/Slco1b1' /* webpackChunkName: "" */))
const _90249f8e = () => interopDefault(import('@/pages/products/genotyping/F2f5' /* webpackChunkName: "" */))
const _0d2b051b = () => interopDefault(import('@/pages/search/Index' /* webpackChunkName: "" */))
const _d32bc108 = () => interopDefault(import('@/pages/about/ShipmentPay' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/BlankPage",
    component: _3f8267b4,
    pathToRegexpOptions: {"strict":true},
    name: "BlankPage"
  }, {
    path: "/Index",
    component: _90e9b0fa,
    pathToRegexpOptions: {"strict":true},
    name: "Index"
  }, {
    path: "/about/About",
    component: _0386d6ff,
    pathToRegexpOptions: {"strict":true},
    name: "about-About"
  }, {
    path: "/about/Contacts",
    component: _8d82a85e,
    pathToRegexpOptions: {"strict":true},
    name: "about-Contacts"
  }, {
    path: "/about/ShipmentPay",
    component: _fc440fc0,
    pathToRegexpOptions: {"strict":true},
    name: "about-ShipmentPay"
  }, {
    path: "/search/Index",
    component: _077e774a,
    pathToRegexpOptions: {"strict":true},
    name: "search-Index"
  }, {
    path: "/products/extraction/Dna2",
    component: _35f1b825,
    pathToRegexpOptions: {"strict":true},
    name: "products-extraction-Dna2"
  }, {
    path: "/products/extraction/DnaFfpeIvd",
    component: _7d14d575,
    pathToRegexpOptions: {"strict":true},
    name: "products-extraction-DnaFfpeIvd"
  }, {
    path: "/products/extraction/Index",
    component: _67476798,
    pathToRegexpOptions: {"strict":true},
    name: "products-extraction-Index"
  }, {
    path: "/products/genotyping/Brca",
    component: _1342bc07,
    pathToRegexpOptions: {"strict":true},
    name: "products-genotyping-Brca"
  }, {
    path: "/products/genotyping/Ces1",
    component: _37c7ef4e,
    pathToRegexpOptions: {"strict":true},
    name: "products-genotyping-Ces1"
  }, {
    path: "/products/genotyping/Cyp2c19",
    component: _d4f1686c,
    pathToRegexpOptions: {"strict":true},
    name: "products-genotyping-Cyp2c19"
  }, {
    path: "/products/genotyping/Cyp2c9vkorc1",
    component: _5509d566,
    pathToRegexpOptions: {"strict":true},
    name: "products-genotyping-Cyp2c9vkorc1"
  }, {
    path: "/products/genotyping/Egfr",
    component: _0b0fd047,
    pathToRegexpOptions: {"strict":true},
    name: "products-genotyping-Egfr"
  }, {
    path: "/products/genotyping/F2f5",
    component: _7beb1ef4,
    pathToRegexpOptions: {"strict":true},
    name: "products-genotyping-F2f5"
  }, {
    path: "/products/genotyping/Index",
    component: _031acec9,
    pathToRegexpOptions: {"strict":true},
    name: "products-genotyping-Index"
  }, {
    path: "/products/genotyping/Insider",
    component: _32af082d,
    pathToRegexpOptions: {"strict":true},
    name: "products-genotyping-Insider"
  }, {
    path: "/products/genotyping/Nat2",
    component: _ee1a0dec,
    pathToRegexpOptions: {"strict":true},
    name: "products-genotyping-Nat2"
  }, {
    path: "/products/genotyping/Ras",
    component: _609a1e4a,
    pathToRegexpOptions: {"strict":true},
    name: "products-genotyping-Ras"
  }, {
    path: "/products/genotyping/Slco1b1",
    component: _990a21dc,
    pathToRegexpOptions: {"strict":true},
    name: "products-genotyping-Slco1b1"
  }, {
    path: "/search/components/AppSearchShowMore",
    component: _8e071a24,
    pathToRegexpOptions: {"strict":true},
    name: "search-components-AppSearchShowMore"
  }, {
    path: "/search/components/SearchBlock",
    component: _13a607b8,
    pathToRegexpOptions: {"strict":true},
    name: "search-components-SearchBlock"
  }, {
    path: "/search/components/SearchResult",
    component: _161330fc,
    pathToRegexpOptions: {"strict":true},
    name: "search-components-SearchResult"
  }, {
    path: "/",
    component: _64b6fd9a,
    children: [{
      path: "",
      component: _59a7c9bc
    }, {
      path: "about",
      component: _1f40664a
    }, {
      path: "extraction",
      component: _db2a7776
    }, {
      path: "extraction/dna2",
      component: _07166fb0
    }, {
      path: "extraction/dna-ffpe",
      component: _63c76590
    }, {
      path: "genotyping",
      component: _460de274
    }, {
      path: "genotyping/insider",
      component: _0a17edd0
    }, {
      path: "genotyping/ras",
      component: _994b1b74
    }, {
      path: "genotyping/egfr",
      component: _9023f9e8
    }, {
      path: "genotyping/brca",
      component: _90266268
    }, {
      path: "genotyping/nat2",
      component: _901bf562
    }, {
      path: "genotyping/cyp2c9-vkorc1",
      component: _4faf8e12
    }, {
      path: "genotyping/cyp2c19",
      component: _5fdeb996
    }, {
      path: "genotyping/ces1",
      component: _9025d7c4
    }, {
      path: "genotyping/slco1b1",
      component: _07a92a7d
    }, {
      path: "genotyping/f2-f5",
      component: _90249f8e
    }, {
      path: "search",
      component: _0d2b051b
    }, {
      path: "shipment-pay",
      component: _d32bc108
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
